



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import GenericModalForm from '@/partials/modals/generic/form.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    ValidationProvider,
    GenericModalForm,
    PasswordConfirmField
  }
})
export default class MedicalPatientEvaluationsForm extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean
  @Prop({ required: true, type: String }) patientId!: string
  form: Record<string, any> = {
    patientId: '',
    medicalFormId: '',
    confirm: '',
    errors: {
      medicalFormId: '',
      confirm: ''
    }
  }

  medicalForms: Array<any> = []

  created () {
    this.form.patientId = this.patientId
    this.fetchForms()
  }

  fetchForms () {
    axios.get('/medical/form/dropdown')
      .then(response => {
        this.medicalForms = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit (response: any) {
    this.$emit('submitted', camelCaseKeys(response, { deep: true }))
  }

  @Watch('visible')
  onVisibleChange (isVisible: boolean) {
    if (!isVisible) {
      Object.assign(this.form, {
        medicalFormId: '',
        confirm: '',
        errors: {
          medicalFormId: '',
          confirm: ''
        }
      })
    }
  }
}
