

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

@Component({
  components: {
    ValidationObserver
  }
})
export default class GenericModalForm extends Vue {
  @Prop({ required: true, type: String }) verb!: 'patch' | 'post' | 'put'
  @Prop({ required: true, type: String }) requestUrl!: string
  @Prop({ required: true }) form!: any
  @Prop({ default: false }) visible!: boolean
  @Prop() title!: string

  closeModal () {
    this.clearForm()
    this.$emit('close')
  }

  submit () {
    this.$emit('submitting')
    axios[this.verb](this.requestUrl, snakecaseKeys(this.form, { deep: true }))
      .then(response => {
        this.$emit('submitted', response.data.data)
        this.clearForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      const eventErrors = {} as Record<string, string>
      Object.entries(errors).map(([key, value]: [string, any]) => {
        eventErrors[key] = value[0]
      })
      this.$emit('error', eventErrors)
    }

    if (response.status === 403) {
      this.$emit('error', response.data.data)
    }
  }

  $refs!: {
    observer: any;
  }

  clearForm () {
    this.$refs.observer.reset()
  }
}
