






import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class PatientFormReportGraph extends Vue {
    @Prop({ required: true, type: String }) patientId!: string
    data = []
    options = {}
    done = false

    created () {
      this.done = false
      axios.get(`/patient/${this.patientId}/form/reports/time`)
        .then((response) => {
          const { data, options } = response.data.data

          this.data = data
          this.options = options
        })
        .finally(() => { this.done = true })
    }
}
