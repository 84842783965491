var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cv-loading',{attrs:{"active":!_vm.fetched,"overlay":""}}),_c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.form.title)+" ")]),_c('RichTextDisplay',{staticClass:"my-2",attrs:{"text":_vm.form.body}}),_c('hr'),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid gap-4 mt-8"},[_vm._l((_vm.form.questions),function(question,index){return _c('div',{key:question.id,staticClass:"mb-8"},[(_vm.getQuestionType(question) === 'info')?_c('div',{staticClass:"mt-16"},[_c('Information32')],1):_vm._e(),_c('p',{staticClass:"font-bold pt-1 py-2"},[_vm._v(" "+_vm._s(question.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(question.body)+" ")]),(question.attachment)?_c('div',[_c('img',{attrs:{"src":question.attachment.publicPath}})]):_vm._e(),(_vm.getQuestionType(question) === 'radio')?_c('ValidationProvider',{attrs:{"tag":"div","rules":"","name":("Questão " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length)?_c('div',{staticClass:"mb-3 text-red-600 font-bold"},[_vm._v(" Selecione uma alternativa ")]):_vm._e(),_c('cv-radio-group',{attrs:{"vertical":""}},[_c('div',{staticClass:"space-y-2"},_vm._l((question.options),function(option){return _c('div',{key:option.id},[_c('div',{staticClass:"flex"},[_c('cv-radio-button',{attrs:{"disabled":_vm.type === 'read' || _vm.type === 'read-form',"name":question.id,"label":option.title,"value":option.id},model:{value:(question.answerId),callback:function ($$v) {_vm.$set(question, "answerId", $$v)},expression:"question.answerId"}})],1),(option.help)?_c('div',{staticClass:"flex ml-6 pb-2 pt-2 space-x-2"},[_c('div',[_c('Information16')],1),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(option.help)+" ")])]):_vm._e()])}),0)])]}}],null,true)}):_vm._e(),(_vm.getQuestionType(question) === 'check')?_c('ValidationProvider',{attrs:{"tag":"div","rules":"","name":("Questão " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length)?_c('div',{staticClass:"mb-3 text-red-600 font-bold"},[_vm._v(" Selecione uma alternativa ")]):_vm._e(),_vm._l((question.options),function(option){return _c('div',{key:option.id},[_c('cv-checkbox',{attrs:{"value":option.id,"label":option.title,"name":question.id,"disabled":_vm.type === 'read' || _vm.type === 'read-form'},model:{value:(question.answerId),callback:function ($$v) {_vm.$set(question, "answerId", $$v)},expression:"question.answerId"}}),(option.help)?_c('div',{staticClass:"flex ml-4 space-x-2"},[_c('div',[_c('Information16')],1),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(option.help)+" ")])]):_vm._e()],1)})]}}],null,true)}):_vm._e(),(_vm.getQuestionType(question) === 'text')?_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":("Questão " + (index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-area',{attrs:{"rows":"5","invalid-message":errors[0],"disabled":_vm.type === 'read' || _vm.type === 'read-form'},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}),(question.options[0].help)?_c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('Information16')],1),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(question.options[0].help)+" ")])]):_vm._e()]}}],null,true)}):_vm._e()],1)}),(_vm.type === 'edit')?_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.passwordConfirmError},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}):_vm._e()],2),_c('div',{staticClass:"mt-4"},[(_vm.done && !['read', 'read-form'].includes(_vm.type))?_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):(!['read', 'read-form'].includes(_vm.type))?_c('cv-button-skeleton'):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }