







import { Component, Vue } from 'vue-property-decorator'

import PatientEvaluationsTable from '@/partials/tables/Medical/Patients/Evaluations.vue'
import PatientFormReportGraph from '@/partials/graphs/Medical/PatientFormReport.vue'
@Component({
  components: {
    PatientEvaluationsTable,
    PatientFormReportGraph
  }
})
export default class MedicalPatientEvaluations extends Vue {
  patientId = ''

  created () {
    this.patientId = this.$route.params.patientId
  }
}
