






import { Component, Vue } from 'vue-property-decorator'

import MedicalFormAnswerForm from '@/partials/forms/Medical/Form/Answer/form.vue'

@Component({
  components: {
    MedicalFormAnswerForm
  }
})
export default class MedicalPatientEvaluationShow extends Vue {
  patientId = ''

  created () {
    this.patientId = this.$route.params.patientId
  }
}
