











































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Link16 from '@carbon/icons-vue/es/link/16'
import View16 from '@carbon/icons-vue/es/view/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import CreatePatientFormModal from '@/partials/modals/Medical/Patients/Evaluations/CreateForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import QRCodeModal from '@/partials/modals/generic/QRCode.vue'

@Component({
  components: {
    QRCodeModal,
    TablePagination,
    Link16,
    View16,
    Edit16,
    TrashCan16,
    CreatePatientFormModal,
    GenericModalDelete
  }
})
export default class MedicalPatientEvaluationsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) patientId!: string
  totalEvaluations = 0
  evaluations: Array<any> = []
  filteredData: Array<any> = []
  selectedEvaluation: any = {
    id: '',
    token: ''
  }

  formAnswerUrl = ''
  selectedForm = ''
  deleteModalIsOpened = false
  selectFormModalIsOpened = false
  shareModalIsOpened = false

  created () {
    this.fetchEvaluations()
  }

  fetchEvaluations () {
    axios.get(`/patient/${this.patientId}/form`)
      .then(response => {
        this.evaluations = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalEvaluations = this.evaluations.length
      })
  }

  generateUrl (formId: string) {
    return window.location.origin + this.$router.resolve({
      name: 'MedicalFormAnswer',
      params: {
        patientId: this.patientId,
        formId
      }
    }).href
  }

  openQrCodeModal () {
    this.selectFormModalIsOpened = false
    this.shareModalIsOpened = true
    this.selectedForm = ''
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Avaliação foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchEvaluations()
  }

  postCreatePatientForm (response: any) {
    this.fetchEvaluations()
    this.selectFormModalIsOpened = false
    this.formAnswerUrl = this.generateUrl(response.id)
    this.openQrCodeModal()
  }

  get deleteUrl () {
    return isEmpty(this.selectedEvaluation) ? '' : `patient/${this.patientId}/form/${this.selectedEvaluation.id}`
  }
}
