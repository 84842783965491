
































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'

import Information16 from '@carbon/icons-vue/es/information/16'
import Information32 from '@carbon/icons-vue/es/information/32'
import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    Information16,
    Information32,
    PasswordConfirmField,
    RichTextDisplay,
    ValidationProvider,
    ValidationObserver
  }
})
export default class MedicalFormAnswer extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ type: String }) type!: 'create' | 'edit' | 'read' | 'read-form'
  @Prop({ type: String }) patientId!: string
  @Prop({ required: true, type: String }) formId!: string

  form = {
    title: '',
    body: '',
    questions: [] as any
  }

  medicalForm = {} as any
  answers = [] as any

  passwordConfirm = ''
  passwordConfirmError = ''

  done = true
  fetched = false

  created () {
    this.fetch()
  }

  submit () {
    let route = `/patient/${this.patientId}/form/${this.medicalForm.id}/answers`
    if (this.type === 'create') {
      route = '/public' + route
    }

    this.done = false

    axios.put(route, snakeCaseKeys(this.submitForm()))
      .then(() => {
        if (this.type === 'create') {
          this.$router.push({ name: 'MedicalFormAnswerThankYou' })
        } else {
          this.$router.push({ name: 'MedicalPatientEvaluations', params: { patientId: this.patientId } }, () => {
            const flashMessage: flashMessage = {
              message: 'Formulário respondido com sucesso.',
              type: 'success'
            }
            this.setFlashMessage(flashMessage)
          })
        }
      })
      .catch(err => {
        const response = err.response

        if (response.errors?.confirm) {
          this.passwordConfirmError = response.errors?.confirm
        }
      })
      .finally(() => { this.done = true })
  }

  private submitForm () {
    return {
      patientId: this.patientId,
      patientMedicalFormId: this.formId,
      confirm: this.passwordConfirm,
      answers: this.form.questions.reduce((result: Array<any>, question: any) => {
        const answerId = this.convertAnswerToApi(question.answerId)
        const answer = question.answer

        if (answerId.length || answer !== '') {
          result.push({
            answer,
            answerId,
            medicalFormQuestionId: question.id
          })
        }
        return result
      }, [])
    }
  }

  getQuestionType (question: any) {
    return question.options[0].type
  }

  fetch () {
    const route = this.type === 'read-form' ? `/medical/form/${this.formId}` : `public/patient/${this.patientId}/form/${this.formId}`

    axios.get(route)
      .then((response) => {
        const casedResponse = camelCaseKeys(response.data.data, { deep: true })
        if (this.type === 'read-form') {
          Object.assign(this.form, casedResponse)
        } else {
          Object.assign(this.form, casedResponse.medicalForm)
          this.medicalForm = casedResponse.medicalForm
          this.answers = casedResponse.answers

          this.form.questions = this.form.questions.map((question: any) => {
            const answers = this.getQuestionAnswer(question)

            return {
              ...question,
              answer: answers.length ? answers[0].value : '',
              answerId: this.convertQuestionAnswerToForm(question, answers)
            }
          })
        }
      })
      .finally(() => { this.fetched = true })
  }

  private getQuestionAnswer (question: any) {
    return this.answers.filter((answer: any) => question.id === answer.medicalFormQuestionId)
  }

  private convertQuestionAnswerToForm (question: any, answers: Array<any>) {
    const questionType = this.getQuestionType(question)
    if (answers.length) {
      return questionType === 'radio' ? answers[0].answerId : answers.map(answer => answer.answerId)
    }

    return questionType === 'check' ? [] : ''
  }

  private convertAnswerToApi (answerId: any) {
    if (answerId === '') {
      return []
    } else {
      return Array.isArray(answerId) ? answerId : [answerId]
    }
  }
}
